import { createBrowserHistory } from 'history';
import { NBXStorage } from '@nbx/frontend-helpers/storage';
import {
  refreshSession,
  refreshAccountToken,
  refreshUserToken,
  refreshTokens,
  context
} from '@nbx/frontend-helpers/auth';
import { publishLang, publishTheme } from '@nbx/frontend-helpers/publish';
import { ApplePayWalletExtension } from '@nbx/capacitor-apple-pay';
import { Firebase, NBXAuth, NBXAssets, NBXMarkets, NBXMessaging, NBXUsers } from 'services';
import {
  getAppStatus,
  mountRootParcel,
  registerApplication,
  start,
  addErrorHandler,
  navigateToUrl
} from 'single-spa';

import reqRateLimiter from 'helpers/reqRateLimiter';

// Shims & Polyfills
import allSettled from '@ungap/promise-all-settled';
import 'requestidlecallback-polyfill';
import 'intersection-observer';

import { App } from '@capacitor/app';
import { Browser } from '@capacitor/browser';
import { setMarketsConfig } from './helpers/markets';

// NBX Stuff
import setupPubSub from './pubsub';
import setupFetchListeners from './fetch';
import { ConfigurationError, MicrofrontendMountError } from './errors';
import { createLogger } from './helpers/Logger';
import { retryPromise } from './helpers/retries';
import LANGUAGES from './constants/languages';
import './i18n';
import { parseSlug } from './helpers/parseSlug';

// Styles
import './root.scss';
import 'normalize.min.css';

// shim the shims
Promise.allSettled = allSettled;

// single-spa state vars
const microfrontends = [];
let error = false;

const log = createLogger('[bootstrap]');

const systemTheme = () =>
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

const setupFromStorage = () => {
  NBXStorage.getItem('theme', { useLocalStorage: true }).then(theme => {
    if (!theme) {
      theme = systemTheme();
      publishTheme(theme);
    }
    document.getElementsByTagName('html')[0].classList.remove('dark');
    document.getElementsByTagName('html')[0].classList.remove('light');
    document.getElementsByTagName('html')[0].classList.add(theme);
  });

  NBXStorage.getItem('i18nextLng', { useLocalStorage: true }).then(lang => {
    if (!lang) {
      lang = 'en';
      publishLang(lang);
    }
  });
};

const LANGUAGE_KEYS = Object.keys(LANGUAGES);
const getLocalePaths = path => {
  return [path, ...LANGUAGE_KEYS.map(lang => `/${lang}${path}`)];
};

// WINDOW VARS
window.LANGUAGES = LANGUAGES;
if (!window.HISTORY) {
  window.HISTORY = createBrowserHistory();
}
window.GET_LOCALE_PATHS = getLocalePaths;
window.REQ_RATE_LIMITER = reqRateLimiter;
window.MOUNT_ROOT_PARCEL = mountRootParcel;
window.FIREBASE = Firebase;
window.MESSAGING = NBXMessaging;
window.REFRESH_TOKENS = (accountId, deviceTokenString) =>
  context.session().then(contextSession => refreshTokens(contextSession, accountId, deviceTokenString));
window.REFRESH_SESSION = refreshSession;
window.GET_SESSION = context.session;
window.GET_USER_TOKEN = refreshUserToken;
window.GET_ACCOUNT_TOKEN = refreshAccountToken;
window.LOGOUT = NBXAuth.logout;
window.SUSPEND_MOBILE_SESSION = NBXAuth.suspendMobileSession;
window.FRONTEND_NODE_ENV = process.env.NODE_ENV;
window.GET_API_CONFIG = () => {
  return new Promise((resolve, reject) => {
    if (window.API_CONFIG) resolve(window.API_CONFIG);
    else {
      fetch('/configs/config.json')
        .then(r => r.json())
        .then(config => {
          if (
            typeof config.api !== 'object' ||
            typeof config.firebase !== 'object' ||
            typeof config.signicat !== 'object'
          ) {
            throw new ConfigurationError('config.json was malformed');
          }
          /**
           * When previewing new endpoints, add them to these objects in sessionStorage
           * i.e. sessionStorage.setItem("preview_apis", { "foo": { "baseUrl": "https://ingress.nbx.com/foo" } } )
           * which mimic the format of the "api" object in config.json. This can add new apis or override existing ones
           */
          NBXStorage.getItem('preview_apis').then(previewAPIs => {
            try {
              if (previewAPIs !== null) config.api = { ...config.api, ...JSON.parse(previewAPIs) };
            } catch (err) {
              console.warn('Something went wrong while parsing preview config overrides', err);
            }
            window.API_CONFIG = config;
            resolve(config);
          });
        })
        .catch(caughtError => {
          reject(caughtError);
        });
    }
  });
};

// util functions
const doesPathMatch = (routeList, path) => {
  return routeList.some(route => {
    if (route.includes(':')) {
      const splitRoutePrefix = route.split(':')[0];
      return path.includes(splitRoutePrefix);
    }
    if (route === path || `${route}/` === path) return true;
    return false;
  });
};

const handleNotFound = async () => {
  const { PLATFORM } = window;
  const dest = document.querySelector('#microfrontend_hook');
  const spinner = document.querySelector('#microfrontend_hook > .spinner');
  try {
    if (spinner) spinner.parentNode.removeChild(spinner);
    const currentLang = await NBXStorage.getItem('i18nextLng', { useLocalStorage: true });
    if (dest == null) {
      console.error(`Could not set error view, document state: ${document.readyState}`);
      return;
    }
    if (LANGUAGES[currentLang]) {
      fetch(`${PLATFORM === 'web' ? '/static' : ''}/${currentLang}/error404.html`)
        .then(data => data.text())
        .then(html => (dest.innerHTML = html));
    } else {
      fetch(`${PLATFORM === 'web' ? '/static' : ''}/en/error404.html`)
        .then(data => data.text())
        .then(html => (dest.innerHTML = html));
    }
  } catch (caughtError) {
    handleError(caughtError);
  }
};

const handleError = async err => {
  try {
    const appSate = getAppStatus(err.appOrParcelName);
    log.info(`handleError getAppStatus(${err.appOrParcelName}) = ${appSate}`);
  } catch (e) {
    console.error('cannot get app state', e);
  }
  const { PLATFORM } = window;
  error = true;
  const dest = document.querySelector('#microfrontend_hook');
  const spinner = document.querySelector('#microfrontend_hook > .spinner');
  try {
    if (spinner) spinner.parentNode.removeChild(spinner);
    const currentLang = await NBXStorage.getItem('i18nextLng', { useLocalStorage: true });
    if (dest == null) {
      console.error(`Could not set error view, document state: ${document.readyState}`);
      return;
    }
    if (LANGUAGES[currentLang]) {
      fetch(`${PLATFORM === 'web' ? '/static' : ''}/${currentLang}/errorGeneral.html`)
        .then(data => data.text())
        .then(html => (dest.innerHTML = html));
    } else {
      fetch(`${PLATFORM === 'web' ? '/static' : ''}/en/errorGeneral.html`)
        .then(data => data.text())
        .then(html => (dest.innerHTML = html));
    }
  } catch (caughtError) {
    console.error(caughtError);
  }
};

const bootstrapMicrofrontends = () => {
  addErrorHandler(handleError);
  return fetch('/configs/microfrontends.json')
    .then(r => r.json())
    .then(async config => {
      if (typeof config.apps !== 'object' || typeof config.routes !== 'object') {
        throw new ConfigurationError('microfrontends.json was malformed');
      }
      /**
       * When previewing new routes or microfrontends, add them to these objects in sessionStorage
       * i.e. sessionStorage.setItem("preview_apps", { "foo": "https://foo.dev.nbx.com" } )
       * i.e. sessionStorage.setItem("preview_routes", { "bar": { "app": "foo", "path", "/bar" } } )
       * which mimic the format of the "apps" and "routes" objects in microfrontends.json.
       * This can also be used to overwrite the configuration of an existing route or microfrontend
       * i.e. sessionStorage.setItem("preview_apps", { "landingpage": "https://frontend-landing-page.dev.nbx.com" })
       * could be used to preview changes to landing page which have been deployed to dev while using
       * another environment, such as prod.
       */
      const previewApps = await NBXStorage.getItem('preview_apps');
      const previewWidgets = await NBXStorage.getItem('preview_widgets');
      const previewRoutes = await NBXStorage.getItem('preview_routes');
      try {
        if (previewApps !== null) config.apps = { ...config.apps, ...JSON.parse(previewApps) };
        if (previewWidgets !== null) config.widgets = { ...config.widgets, ...JSON.parse(previewWidgets) };
        if (previewRoutes !== null) config.routes = { ...config.routes, ...JSON.parse(previewRoutes) };
      } catch (err) {
        console.warn('Something went wrong while parsing preview config overrides', err); // eslint-disable-line
      }
      window.MICROFRONTENDS_CONFIG = config;
      const appKeys = Object.keys(config.apps);
      const routeKeys = Object.keys(config.routes);
      const pathsForApps = {};
      routeKeys.forEach(routeKey => {
        const route = config.routes[routeKey];
        if (pathsForApps[route.app]) pathsForApps[route.app].push(...getLocalePaths(route.path));
        else pathsForApps[route.app] = getLocalePaths(route.path);
      });
      appKeys.forEach(appKey => {
        const appURL = config.apps[appKey];
        if (appKey === 'frame') {
          microfrontends.push({
            name: appKey,
            isActive: () => true,
            remoteEntry: `${appURL}/remoteEntry.js`
          });
        } else {
          microfrontends.push({
            name: appKey,
            isActive: () => {
              return !error && doesPathMatch(pathsForApps[appKey] ?? [], location.pathname);
            },
            remoteEntry: appURL !== '' ? `${appURL}/remoteEntry.js` : null
          });
        }
      });
      loadModule();
      window.HISTORY.listen(() => {
        error = false;
        if (window.PLATFORM === 'web') loadModule();
      });
      return config;
    })
    .catch(handleError);
};

const loadModule = () => {
  let anyActive = false;

  microfrontends.forEach(async microfrontend => {
    try {
      // If any are active, set the flag
      if (microfrontend.name !== 'frame' && microfrontend.isActive()) {
        anyActive = true;
        if (!microfrontend.remoteEntry) {
          handleError(new ConfigurationError(`${microfrontend} has no remote entry route specified`));
          return;
        }
      }

      // If a microfrontend is loading for this first time, import and load the module
      // If the frontend has been previously loaded, then we just need to wait for singleSPA to re-initialize it
      const appStatus = getAppStatus(microfrontend.name);
      if (appStatus === null) {
        // Show the loading spinner on web
        if (microfrontend.isActive() && window.PLATFORM === 'web' && microfrontend.name !== 'frame') {
          const dest = document.querySelector(`#microfrontend_hook`);
          setTimeout(() => {
            if (dest) {
              dest.innerHTML = '<div class="spinner" />';
            }
          });
        }

        if (microfrontend.isActive() || window.PLATFORM !== 'web') {
          // If it hasn't been previously loaded, load the module
          // in the app we just load them all up front since they don't require external network requests
          const registerCallback = () => {
            return new Promise((resolve, reject) => {
              let container;
              const element = document.createElement('script');
              element.src = microfrontend.remoteEntry;
              if (window.PLATFORM !== 'web') {
                element.src = `/${microfrontend.name}.remoteEntry.js`;
              }
              element.type = 'text/javascript';
              element.async = true;
              element.onload = async () => {
                await __webpack_init_sharing__('default'); // eslint-disable-line
                container = window[microfrontend.name];
                await container.init(__webpack_share_scopes__.default); // eslint-disable-line
                const factory = await window[microfrontend.name].get('./app');
                const Module = factory();
                resolve(Module);
              };
              element.onerror = () => {
                const message = `Microfrontend module failed to load: ${microfrontend.name}`;
                console.error(message);
                reject(new MicrofrontendMountError(message));
              };
              document.head.appendChild(element);
            });
          };

          registerApplication(microfrontend.name, retryPromise(registerCallback), microfrontend.isActive);
        }
      }
    } catch (e) {
      console.error(e);
      handleError(new MicrofrontendMountError(e));
    }
  });
  if (!anyActive && !error) {
    handleNotFound();
  }
};

/* eslint-disable no-alert, no-shadow, no-empty, spaced-comment, no-console */

const setupMobileListeners = platform => {
  if (platform === 'web') return;

  window.IS_APP_ACTIVE = true;
  window.addEventListener('applicationDidBecomeActive', () => (window.IS_APP_ACTIVE = true));
  window.addEventListener('applicationWillResignActive', () => (window.IS_APP_ACTIVE = false));

  App.addListener('appUrlOpen', event => {
    try {
      if (event.url?.startsWith('com.nbx.exchange://')) Browser.close();

      const slug = parseSlug(event.url);
      log.info('appUrlOpen', { url: event.url, slug });

      if (/redirectResult/.test(slug)) {
        log.info('received redirect from most likely an adyen payment, skipping all deep link events');
        return;
      }

      if (slug) {
        if (/\/funds/.test(slug) || /\/login\/signup\?/.test(slug)) {
          window.location.replace(slug);
        } else {
          navigateToUrl(slug);
        }
        window.deepLinkTarget = slug;
        setTimeout(() => {
          try {
            window.deepLinkTarget = null;
          } catch (e) {
            console.error(e);
          }
        }, 10000);
      }
    } catch (e) {
      console.error(e);
      throw e;
    }
  });
};

const startNBX = platform => {
  window.PLATFORM = platform;
  window
    .GET_API_CONFIG()
    .then(async apiconfig => {
      if (platform !== 'web') console.log('bootstrap', platform);
      setMarketsConfig(apiconfig.markets);
      Firebase.setConfig(apiconfig.firebase);
      NBXAuth.init(apiconfig.api.auth);
      NBXAssets.init(apiconfig.api.assets);
      NBXUsers.init(apiconfig.api.users);
      NBXMarkets.init(apiconfig.api.markets);
      NBXMessaging.init(apiconfig.api.messaging);
      ApplePayWalletExtension.init(apiconfig.api.card);
      setupPubSub();
      setupFromStorage(platform);
      setupMobileListeners(platform);
      await setupFetchListeners(apiconfig.api);
      start();
      bootstrapMicrofrontends(platform);
      if (platform !== 'web') console.log('bootstrap', platform, 'done');
    })
    .catch(handleError);
};

export default startNBX;
