import { FirebaseAuthentication } from '@nbx/capacitor-firebase-authentication';
import { Capacitor } from '@capacitor/core';

import { NBXStorage } from '@nbx/frontend-helpers/storage';
import isMobileDevice from '../helpers/isMobileDevice';
import { createLogger } from '../helpers/Logger';
import importFirebase from './firebase-async';
import NBXUsers from './users.service';

let firebaseConfig = {};
let appInstance = null;
let importedFirebaseAuth = null;
let vippsOpenIDConnectProvider = null;
let googleOpenIDConnectProvider = null;
let appleOpenIDConnectProvider = null;

const log = createLogger('[firebase]');
const isAndroidBrowser = /(android)/i.test(navigator.userAgent);
const VIPPS_SCOPES = 'openid api_version_2 address birthDate name phoneNumber';

const Firebase = {
  setConfig: config => {
    if (isAndroidBrowser) {
      config.authDomain = config.authDomain.replace('app', 'auth');
    }
    return (firebaseConfig = config);
  },

  // https://firebase.google.com/docs/reference/js/app.md#initializeapp
  initializeApp: async config => {
    if (importedFirebaseAuth) return importedFirebaseAuth;

    const [firebaseApp, firebaseAuth] = await importFirebase();
    appInstance = firebaseApp.initializeApp(config);
    // See https://github.com/firebase/firebase-js-sdk/issues/5019
    if (window.PLATFORM !== 'web') {
      firebaseAuth.initializeAuth(appInstance, {
        persistence: firebaseAuth.indexedDBLocalPersistence
      });
    }

    vippsOpenIDConnectProvider = new firebaseAuth.OAuthProvider('oidc.vipps');
    vippsOpenIDConnectProvider.addScope(VIPPS_SCOPES);

    googleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('google.com');

    appleOpenIDConnectProvider = new firebaseAuth.OAuthProvider('apple.com');

    importedFirebaseAuth = firebaseAuth;
    return importedFirebaseAuth;
  },

  importAuth: async () => {
    return Firebase.initializeApp(firebaseConfig);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getauth
  auth: () => Firebase.importAuth().then(firebaseAuth => firebaseAuth.getAuth(appInstance)),

  // https://firebase.google.com/docs/reference/js/auth.auth.md#auth_interface
  user: async () => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native user()');
        return nativeUser.user;
      }
    }
    return Firebase.auth().then(auth => auth.currentUser);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#getidtoken
  getIdToken: async forceRefresh => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native getIdToken()');
        return FirebaseAuthentication.getIdToken({ forceRefresh }).then(token => token.token);
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.getIdToken(user, forceRefresh);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#createuserwithemailandpassword
  signUp: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.createUserWithEmailAndPassword(auth, email, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#getadditionaluserinfo
  getAdditionalUserInfo: userCredential =>
    Firebase.importAuth().then(firebaseAuth => {
      return firebaseAuth.getAdditionalUserInfo(userCredential);
    }),

  onAuthStateChanged: async (nextOrObserver, error) => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.onAuthStateChanged(auth, nextOrObserver, error);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#signinwithemailandpassword
  login: (email, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.signInWithEmailAndPassword(auth, email, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#signinwithpopup
  // https://firebase.google.com/docs/reference/js/auth.md#signinwithredirect
  signInWithIDP: async (auth, provider) => {
    const firebaseAuth = await Firebase.importAuth();
    return Firebase.isRedirectFlow()
      ? firebaseAuth.signInWithRedirect(auth, provider)
      : firebaseAuth.signInWithPopup(auth, provider);
  },

  vippsLogin: () => {
    if (Capacitor.isNativePlatform())
      return FirebaseAuthentication.signInWithCustomProvider({
        providerId: 'oidc.vipps',
        scopes: VIPPS_SCOPES.split(' ')
      });

    return Firebase.importAuth().then(firebaseAuth =>
      Firebase.signInWithIDP(firebaseAuth.getAuth(), vippsOpenIDConnectProvider).then(result => {
        const { accessToken } = firebaseAuth.OAuthProvider.credentialFromResult(result);
        NBXUsers.vippsUserInfo(accessToken)
          .then(async userInfo => {
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'userInfo', JSON.stringify(userInfo));
            await NBXStorage.setItem('vippsUserInfo', JSON.stringify(userInfo));
            // eslint-disable-next-line no-console
            console.info('vippsLogin', 'stored user info');
          })
          .catch(error => {
            console.error('vippsLogin', error);
          });

        return result;
      })
    );
  },

  googleLogin: () => {
    if (Capacitor.isNativePlatform()) return FirebaseAuthentication.signInWithGoogle();

    return Firebase.importAuth().then(firebaseAuth =>
      Firebase.signInWithIDP(firebaseAuth.getAuth(), googleOpenIDConnectProvider)
    );
  },

  appleLogin: () => {
    if (Capacitor.isNativePlatform()) return FirebaseAuthentication.signInWithApple();

    return Firebase.importAuth().then(firebaseAuth =>
      Firebase.signInWithIDP(firebaseAuth.getAuth(), appleOpenIDConnectProvider)
    );
  },

  getRedirectResult: async () => {
    const firebaseAuth = await Firebase.importAuth();
    const auth = await firebaseAuth.getAuth();
    return firebaseAuth.getRedirectResult(auth);
  },

  isRedirectFlow: () => isMobileDevice(),

  // https://firebase.google.com/docs/reference/js/auth.md#signout
  logout: async () => {
    if (Capacitor.isNativePlatform()) {
      const nativeUser = await FirebaseAuthentication.getCurrentUser();
      if (nativeUser?.user) {
        log.info('Using native signout()');
        return FirebaseAuthentication.signOut();
      }
    }

    return Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.signOut(auth);
    });
  },

  // https://firebase.google.com/docs/reference/js/auth.md#reload
  reloadUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.reload(user);
    }),

  reauthenticate: (email, password) => {
    // We rely on authservice to maintain sessions and so don't maintain a Firebase session
    // so to "reauthenticate" we just login again
    return Firebase.login(email, password);
  },

  // https://firebase.google.com/docs/reference/js/auth.md#sendpasswordresetemail
  resetPassword: email =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.sendPasswordResetEmail(auth, email);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#sendemailverification
  sendEmailVerification: settings =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.sendEmailVerification(user, settings);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#checkactioncode
  checkActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.checkActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#applyactioncode
  applyActionCode: actionCode =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.applyActionCode(auth, actionCode);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#confirmpasswordreset
  confirmPasswordReset: (actionCode, password) =>
    Firebase.importAuth().then(async firebaseAuth => {
      const auth = await firebaseAuth.getAuth();
      return firebaseAuth.confirmPasswordReset(auth, actionCode, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updateemail
  changeEmail: newEmail =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updateEmail(user, newEmail);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#updatepassword
  changePassword: password =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.updatePassword(user, password);
    }),

  // https://firebase.google.com/docs/reference/js/auth.md#deleteuser
  deleteUser: () =>
    Firebase.importAuth().then(async firebaseAuth => {
      const user = await Firebase.user();
      return firebaseAuth.deleteUser(user);
    })
};

export default Firebase;
