/* eslint-disable no-console */
// eslint-disable-next-line import/no-cycle
import { Firebase } from 'services';
import { NBXStorage } from '@nbx/frontend-helpers/storage';
import { post } from '@nbx/frontend-helpers/fetch-api';
import { context, removeDeviceToken, isDeviceTokenExists } from '@nbx/frontend-helpers/auth';
import { ApplePayWalletExtension } from '@nbx/capacitor-apple-pay';
import {
  publishAuthUserChanged,
  publishAuthSessionChanged,
  publishAuthAccountIdChanged,
  publishAuthUserClaimsChanged
} from '@nbx/frontend-helpers/publish';
import { privateStore } from 'fetch/stores';

let baseUrl = 'auth';

const microapps = window.MICROFRONTENDS_CONFIG;

// prettier-ignore
const NBXAuth = {
  init: config => ({ baseUrl } = config),

  logout: () => context.session()
    .then(session => post(`${baseUrl}/logout`, {}, session))
    .catch(error => console.error(error)) // eslint-disable-line
    .finally(async () => {
      publishAuthSessionChanged(null);
      publishAuthAccountIdChanged(null);
      publishAuthUserChanged(null);
      publishAuthUserClaimsChanged({ roles: [] });
      window.DD_RUM?.clearUser(); // NOTE: using imported datadogRum is not working properly

      console.log('auth.service: LOGOUT');

      // On mobile, we need to explicity preserve theme & lang to avoid broken appearance
      // On web, these values are stored in localStorage (instead of sessionStorage), and so are never cleared
      if (window.PLATFORM === 'ios' || window.PLATFORM === 'android') {
        const keysResult = await NBXStorage.keys();
        keysResult.keys.forEach(key => {
          if (!['theme', 'i18nextLng'].includes(key)) {
            NBXStorage.removeItem(key);
          }
        });
        try {
          console.log('auth.service: LOGOUT - removeItemSecure');
          await removeDeviceToken();
          if (window.PLATFORM === 'ios') {
            await ApplePayWalletExtension.clear();
          }
          if (await isDeviceTokenExists()) console.log('token was not removed');
        } catch (e) {
          console.error(`Failed removeItemSecure [${window.PLATFORM}]`, e.message);
        }
      } else {
        NBXStorage.clear();
      }

      Firebase.logout();
      // clear fetch cache of private user info
      if (window.CAN_CACHE_IDB) privateStore.clear();
    }),

    suspendMobileSession:  async () => {
        console.log('auth.service: LOGOUT - suspendMobileSession');
        window.HISTORY.push(microapps?.routes?.mobileSuspendedSession?.path);
        publishAuthSessionChanged(null);
        publishAuthAccountIdChanged(null);
        publishAuthUserChanged(null);
        publishAuthUserClaimsChanged({ roles: [] });
        if (window.PLATFORM === 'ios' || window.PLATFORM === 'android') {
          (await NBXStorage.keys())?.keys?.filter(key => (!key.startsWith('TOS-ACCEPTED') && !key.startsWith('CARD-PAYMENT-TOS-ACCEPTED') && !['theme', 'i18nextLng', 'applePayStartupSeen', 'applePayPendingCardVerificationSeen'].includes(key))).forEach(NBXStorage.removeItem);
        }

      },
};

export default NBXAuth;
