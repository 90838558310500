import { NBXStorage } from '../storage';
// eslint-disable-next-line import/no-cycle
import { post } from '../fetch-api';
import { getAuthBaseUrl } from './url';
import { Claims, Session } from '../types';
// eslint-disable-next-line import/no-cycle
import { getSession } from './session';

export interface UserTokenResponse {
  claims: Claims;
  exp: number;
  token: string;
}

let fetchUserTokenPromise: Promise<string> | undefined | null;

export const clearGlobalFetchUserTokenPromise = (): void => {
  fetchUserTokenPromise = null;
};

export async function refreshUserToken(session: Session): Promise<string> {
  if (fetchUserTokenPromise) return fetchUserTokenPromise;
  fetchUserTokenPromise = doRefreshUserToken(session);
  return fetchUserTokenPromise;
}

let userTokenRefreshTimeout: ReturnType<typeof setTimeout>;

export const setUserTokensDebounceRefresh = (response: UserTokenResponse): void => {
  if (userTokenRefreshTimeout) clearTimeout(userTokenRefreshTimeout);
  userTokenRefreshTimeout = setTimeout(
    () => getSession().then(refreshUserToken),
    (response.claims.exp - response.claims.iat) * 1000 * 0.65
  );
};

export async function doRefreshUserToken(session: Session): Promise<string> {
  try {
    const response = (await post(
      `${getAuthBaseUrl()}/users/${session.userId}/tokens`,
      { json: {} },
      session
    )) as UserTokenResponse;
    await NBXStorage.setItem(session.userId, response.token);
    setUserTokensDebounceRefresh(response);
    return response.token;
  } catch (e) {
    console.warn('doRefreshUserToken: LOGOUT - failed to refresh user token using session: ', session);
    if (window.PLATFORM === 'web') {
      window.LOGOUT();
    } else {
      window.SUSPEND_MOBILE_SESSION();
    }
    throw e;
  } finally {
    clearGlobalFetchUserTokenPromise();
  }
}
