import humps from 'humps';
import { MarketsFromJWT } from '../types';
// eslint-disable-next-line import/no-cycle
import { context } from '../auth';
// TODO: figure out how to eliminate the cyclical dependency here
// eslint-disable-next-line import/no-cycle
import { get } from '../fetch-api';

export interface Market {
  baseAsset: string;
  baseCurrency: string;
  baseIncrement: string;
  baseSizeMinimum: string;
  cancelOnly: boolean;
  disabled: boolean;
  id: string;
  limitOnly: boolean;
  makerFee?: string;
  minQty: number;
  minQtyLength: number;
  minTick: number;
  minTickLength: number;
  name: string;
  pair: string;
  quoteAsset: string;
  quoteCurrency: string;
  quoteIncrement: string;
  status: string;
  statusDescription?: string;
  takerFee?: string;
  isQuickbuyEnabled: boolean;
  quickbuyBaseAssetMaximum: string;
  quickbuyQuoteAssetMaximum: string;
}

export async function fetchAndFormatMarkets(
  marketsFromJWT: MarketsFromJWT = {},
  accountToken?: string
): Promise<Array<Market>> {
  const marketsInfo = await NBXMarkets.getMarkets(accountToken);
  const formattedMarkets = marketsInfo.map(market => {
    const formattedJWTMarket: Partial<Market> = humps.camelizeKeys(marketsFromJWT[market.id]);
    return {
      ...market,
      pair: market.id,
      // Translate to old data format for compat
      baseCurrency: market.baseAsset,
      quoteCurrency: market.quoteAsset,
      minTick: Number.parseFloat(market.quoteIncrement),
      minQty: Number.parseFloat(market.baseIncrement),
      minTickLength: `${market.quoteIncrement}`.split('.')[1].length,
      minQtyLength: `${market.baseIncrement}`.split('.')[1].length,
      // Override trading fees with value from JWT
      makerFee: market.disabled ? formattedJWTMarket?.makerFee : formattedJWTMarket.makerFee ?? '0.5',
      takerFee: market.disabled ? formattedJWTMarket?.takerFee : formattedJWTMarket.takerFee ?? '0.7'
    };
  });
  return formattedMarkets;
}

const NBXMarkets = {
  // TODO: I don't love this
  getBaseUrl: (): void => {
    return window.API_CONFIG?.api.markets?.baseUrl;
  },

  getMarkets: async (accountToken?: string): Promise<Array<Market>> => {
    let accountContext;
    if (!accountToken) {
      try {
        accountContext = await context.account();
      } catch (error) {
        console.warn('failed to get account context, fetching public markets');
      }
    } else {
      accountContext = { token: accountToken };
    }
    return get(`${NBXMarkets.getBaseUrl()}/?helpers=1/`, accountContext) as Promise<Array<Market>>;
  }
};

export default NBXMarkets;
