// eslint-disable-next-line import/no-cycle
import { fetchAndFormatAssets } from '../services/assets.service';
import { fetchAndFormatMarkets } from '../services/markets.service';
import { publishAuthAccountClaimsChanged, publishAuthAccountIdChanged } from '../publish';
import { NBXStorage } from '../storage';
import { post } from '../fetch-api';
import { getAuthBaseUrl } from './url';
// eslint-disable-next-line import/no-cycle
import { getSession, setSessionTokensRefreshTimeout } from './session';
import { AccountClaims, Session } from '../types';

export interface AccountTokenResponse {
  claims: AccountClaims;
  exp: number;
  token: string;
}

let fetchAccountTokenPromiseId;
let fetchAccountTokenPromise;

export const clearGlobalFetchAccountTokenPromise = (): void => {
  fetchAccountTokenPromiseId = null;
  fetchAccountTokenPromise = null;
};

export async function refreshAccountToken(
  session: Session,
  activeAccountId: string
  // logoutOn403 = true
): Promise<string> {
  const accountId = activeAccountId ?? session.accountId;
  if (fetchAccountTokenPromise && accountId === fetchAccountTokenPromiseId) return fetchAccountTokenPromise;

  fetchAccountTokenPromiseId = accountId;
  fetchAccountTokenPromise = doRefreshAccountToken(session, accountId);

  return fetchAccountTokenPromise;
}

let accountTokenRefreshTimeout: ReturnType<typeof setTimeout>;

export const setAccountTokensDebounceRefresh = (
  response: AccountTokenResponse,
  activeAccountId: string
): void => {
  if (accountTokenRefreshTimeout) clearTimeout(accountTokenRefreshTimeout);
  accountTokenRefreshTimeout = setTimeout(
    () => getSession().then(session => refreshAccountToken(session, activeAccountId)),
    (response.claims.exp - response.claims.iat) * 1000 * 0.65
  );
};

export async function doRefreshAccountToken(session: Session, accountId: string): Promise<string> {
  try {
    const response = (await post(`${getAuthBaseUrl()}/accounts/${accountId}/tokens`, { json: {} }, session, {
      camelizeResponseKeys: false
    })) as AccountTokenResponse;
    NBXStorage.setItem(accountId, response.token);

    setAccountTokensDebounceRefresh(response, accountId);

    const markets = await fetchAndFormatMarkets(response.claims.markets, response.token);
    const assets = await fetchAndFormatAssets(response.claims.assets, response.token);

    if (window.CAN_CACHE_IDB) {
      const time = new Date().getTime();
      window.IDB_PUBLIC_STORE.set('markets', { data: markets, time });
      window.IDB_PUBLIC_STORE.set('assets', { data: assets, time });
    }

    publishAuthAccountIdChanged(accountId);
    publishAuthAccountClaimsChanged(response.claims);
    setSessionTokensRefreshTimeout(session);
    return response.token;
  } catch (e) {
    console.warn('doRefreshAccountToken: LOGOUT - failed to refresh account token using session: ', session);
    if (window.PLATFORM === 'web') {
      window.LOGOUT();
    } else {
      window.SUSPEND_MOBILE_SESSION();
    }
    throw e;
  } finally {
    clearGlobalFetchAccountTokenPromise();
  }
}
