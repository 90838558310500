import humps from 'humps';
// eslint-disable-next-line import/no-cycle
import { Context } from '../auth';
import { parseResponseBody } from './response';

enum TokenClaimsEnum {
  jti = 'jti',
  act = 'act',
  sub = 'sub',
  roles = 'roles',
  iss = 'iss',
  aud = 'aud',
  iat = 'iat',
  exp = 'exp',
  session = 'session'
}

type ParsedJWT = {
  [key in TokenClaimsEnum]?: string;
};

const parseJwt = (token?: string): ParsedJWT | null => {
  if (!token) return null;
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

interface APIFetchErrorProps {
  message: string;
  request: FetchRequest;
  response: Response;
  originalError?: Record<string, unknown> | null;
  contextId?: string;
  contextToken?: string;
}

export class APIFetchError extends Error {
  response: Response;

  originalError?: Record<string, unknown> | null;

  url: string;

  constructor({ message, request, response, originalError, contextId, contextToken }: APIFetchErrorProps) {
    message = message ?? 'API fetch error';
    try {
      const { jti, act, sub, roles, iss, aud, iat, exp, session } = parseJwt(contextToken) ?? {};
      message = JSON.stringify({
        message,
        request: {
          ...request,
          body: request.body?.slice(0, 100),
          headers: { ...request.headers, Authorization: '/*removed*/' }
        },
        response,
        originalError,
        contextId,
        contextToken: {
          jti,
          act,
          sub,
          roles,
          iss,
          aud,
          iat: iat && new Date(+iat * 1000),
          exp: exp && new Date(+exp * 1000),
          session
        }
      });
    } catch (e) {
      console.warn('cannot stringify error messsage');
    }
    super(message);
    this.response = response;
    this.url = request.url;
    this.originalError = originalError;
  }
}

export interface FetchRequest {
  url: string;
  method: string;
  body?: string;
  headers?: Record<string, string>;
}

export interface NextPageInfo {
  nextPageUrl: string;
  pagingState: string;
}

export interface RequestOptions {
  decamelizeRequestKeys?: boolean;
  camelizeResponseKeys?: boolean;
  returnHeaders?: boolean;
  accept?: string;
}

export interface Payload {
  headers?: Record<string, string>;
  json?: Record<string, unknown>;
}

export interface PagedResponse {
  body: string | Record<string, unknown>;
  nextPageUrl?: string;
  pagingState?: string;
}

export const createRequest = (
  method: string,
  url: string,
  payload: Payload = {},
  accept = 'application/json',
  options?: RequestOptions
): FetchRequest => {
  const { headers = {}, json = null } = payload;
  const decamelizeRequestKeys = options?.decamelizeRequestKeys ?? true;

  const request = {
    url,
    method,
    body: json ? JSON.stringify(decamelizeRequestKeys ? humps.decamelizeKeys(json) : json) : undefined,
    headers: {
      ...headers,
      Accept: accept
    }
  };

  if (json) request.headers['Content-Type'] = 'application/json';
  return request;
};

export const sendRequest = async (
  request: FetchRequest,
  context?: Context,
  accept = 'application/json',
  options?: RequestOptions
): Promise<string | Record<string, unknown>> => {
  const response = await doFetch(request, context);
  const handled = await handleStatus(response, request, context);
  return parseResponseBody(handled, accept, options);
};

export const sendRequestExternal = async (
  request: FetchRequest,
  accept = 'application/json',
  options?: RequestOptions
): Promise<string | Record<string, unknown>> => {
  const response = await doFetchExternal(request);
  const handled = await handleStatus(response, request, undefined, true);
  return parseResponseBody(handled, accept, options);
};

export const sendPagedRequest = async (
  request: FetchRequest,
  context?: Context,
  accept = 'application/json',
  options?: RequestOptions
): Promise<PagedResponse> => {
  const response = await doFetch(request, context);
  const handled = await handleStatus(response, request, context);
  const parsedBody = await parseResponseBody(handled, accept, options);
  const nextPageInfo = await handlePagingState(handled);
  if (nextPageInfo !== null) {
    const { pagingState, nextPageUrl } = nextPageInfo;
    let envFormattedNextPageUrl: string | undefined;
    if (nextPageUrl) {
      envFormattedNextPageUrl = nextPageUrl;
      if (window.location.origin.includes('localhost') && window.PLATFORM === 'web') {
        envFormattedNextPageUrl = envFormattedNextPageUrl.replace(
          'https://api.nbx.com',
          `${window.location.origin}/api`
        );
        envFormattedNextPageUrl = envFormattedNextPageUrl.replace(
          'https://api-preview.nbx.com',
          `${window.location.origin}/api`
        );
      }
    }

    return {
      body: parsedBody,
      pagingState,
      nextPageUrl: envFormattedNextPageUrl
    };
  }
  return { body: parsedBody };
};

export const handleStatus = async (
  response: Response,
  request: FetchRequest,
  context?: Context,
  external = false,
  retry = false
): Promise<Response> => {
  if (response.ok) {
    return response;
  }

  if (!retry && (response.status === 401 || response.status === 403)) {
    if (external) {
      return handleStatus(
        await doFetchExternal(request),
        request,
        undefined,
        true, // this is external
        true // is a retry
      );
    }
    if (context && typeof context.refresh === 'function') {
      const refreshedContext = await context.refresh();
      return handleStatus(
        await doFetch(request, refreshedContext),
        request,
        context,
        external,
        true // is a retry
      );
    }
  }

  let originalError: Record<string, unknown> | null = null;

  try {
    if (response?.headers?.get('content-type')?.includes('application/json'))
      originalError = await response.json();
  } catch (e) {
    console.warn('cannot parse response.json() from an API');
  }

  if (response.status === 401 && window.PLATFORM === 'web') {
    window.LOGOUT();
  }
  // message, response, originalError, url, method, body, headers
  throw new APIFetchError({
    message: `Api call failed: ${response.status}: ${response.statusText}`,
    request,
    response,
    originalError,
    contextId: context?.id,
    contextToken: context?.token
  });
};

const handlePagingState = async (response): Promise<NextPageInfo | null> => {
  if (!response.headers) return null;
  return {
    pagingState: response.headers.get('x-paging-state'),
    nextPageUrl: response.headers.get('x-next-page-url')
  };
};

export const doFetch = async (request: FetchRequest, context?: Context): Promise<Response> => {
  const { method, url, headers = {}, body } = request;
  const token = context ? context.token : null;
  if (token !== null && token !== undefined) {
    const bearer = `bearer ${token}`;
    headers.Authorization = bearer;
  }
  return fetch(url, {
    method,
    body,
    credentials: 'include',
    headers
  });
};

export const doFetchExternal = async (request: FetchRequest): Promise<Response> => {
  const { method, url, headers, body } = request;
  return fetch(url, {
    method,
    body,
    headers
  });
};
