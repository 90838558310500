import { publishAuthSessionChanged, publishAuthUserClaimsChanged } from '../publish';
import { NBXStorage } from '../storage';
// eslint-disable-next-line import/no-cycle
import { APIFetchError, post } from '../fetch-api';
import { getAuthBaseUrl } from './url';
import { Session } from '../types';
// eslint-disable-next-line import/no-cycle
import { Context } from './context';

const log = (...args: unknown[]) => console.log('helpers.auth.session:', ...args);

export class SessionTokenError extends Error {
  constructor(message = 'Cannot get session token from storage') {
    super(message);
    Object.setPrototypeOf(this, SessionTokenError.prototype);
    this.name = 'SessionTokenError';
  }
}

export const getSession = async (refresh?: boolean): Promise<Session> => {
  const rawSession = await NBXStorage.getItem('session');
  if (!rawSession) throw new SessionTokenError();
  const session = JSON.parse(rawSession);
  return refresh ? refreshSession(session) : session;
};

async function handleNewSession(responseSession: Session): Promise<void> {
  publishAuthSessionChanged(responseSession);
  const { claims } = responseSession;
  if (responseSession.claims.roles.includes('KYC')) {
    NBXStorage.removeItem(`KYC-ACCEPTED-${responseSession.userId}`);
    claims.roles.filter(role => role !== 'KYC-ACCEPTED'); // TODO: ??? roles.filter is immutable
  } else {
    const kycAcceptedForUser = await NBXStorage.getItem(`KYC-ACCEPTED-${responseSession.userId}`);
    if (kycAcceptedForUser) claims.roles.push('KYC-ACCEPTED');
  }
  publishAuthUserClaimsChanged(claims);
}

export async function fetchSessionFromDeviceToken(
  deviceTokenJSON: string | null | undefined = null
): Promise<Session> {
  log('fetchSessionFromDeviceToken');
  if (!deviceTokenJSON)
    throw new Error(
      'Failed to fetch a new session from device token: deviceTokenFromBiometrics param is null'
    );
  const res = (await post(`${getAuthBaseUrl()}/verify/device`, {
    json: { token: JSON.parse(deviceTokenJSON).token }
    // headers: { 'x-nbx-override-expire': '600' }
  })) as Session;
  if (!res) throw new Error('Failed to fetch a new session from device token: session response in empty');
  return res;
}

export async function fetchSessionFromSession(session: Context): Promise<Session> {
  log('fetchSessionFromSession');
  if (!session) throw new Error('Failed to fetch a new session: session param is null');
  const res = (await post(
    `${getAuthBaseUrl()}/refresh`,
    {
      // headers: { 'x-nbx-override-expire': '600' }
    },
    session
  )) as Session;
  if (!res) throw new Error('Failed to fetch a new session: session response in empty');
  return res;
}

let fetchSessionTokenPromise: Session | PromiseLike<Session> | null = null;

export const clearGlobalFetchSessionTokenPromise = (): void => {
  fetchSessionTokenPromise = null;
};

export async function refreshSession(
  session: Context | null,
  deviceTokenJSON: string | null | undefined = null
): Promise<Session> {
  if (fetchSessionTokenPromise) {
    // If there is an existing refresh-in-progress and we don't have a device token, piggyback on the existing
    if (!deviceTokenJSON) return fetchSessionTokenPromise;
    // If we need to start a new refresh (we have a device token), but there already is one in progress, then we wait for it finish
    // We don't care what the result is, it just needs to be done
    try {
      // eslint-disable-next-line
      console.info('Waiting for refresh-in-progress to complete before starting another with device token');
      await fetchSessionTokenPromise;
    } catch (error) {
      console.warn('Got error while waiting for refresh-in-progress, ignoring.', error);
    }
  }

  fetchSessionTokenPromise = doRefreshSession(session, deviceTokenJSON);
  return fetchSessionTokenPromise;
}

export const doRefreshSession = async (
  session: Context | null,
  deviceTokenJSON: string | null | undefined = null,
  isRetry = false
): Promise<Session> => {
  try {
    const newSession = await (deviceTokenJSON
      ? fetchSessionFromDeviceToken(deviceTokenJSON)
      : session && fetchSessionFromSession(session));
    if (!newSession) throw new Error('doRefreshSession: new session is null');
    handleNewSession(newSession);
    log('new session has been created');
    return newSession;
  } catch (e) {
    if (isRetry || (e instanceof APIFetchError && e.response?.status === 403)) {
      console.warn('doRefreshSession: failed to refresh session token - LOGOUT');
      if (window.PLATFORM === 'web') {
        window.LOGOUT();
      } else if (!window.IS_APP_ACTIVE) window.SUSPEND_MOBILE_SESSION();
      throw e;
    } else {
      console.warn(`doRefreshSession: Session token refresh failed (will retry): ${e}`);
      return await getSession().then(session => doRefreshSession(session, deviceTokenJSON, true));
    }
  } finally {
    clearGlobalFetchSessionTokenPromise();
  }
};

export const SESSION_LAST_ACTIVE_TIME_EPOCH = 'session:last_active_time_epoch';

let sessionTokenRefreshTimeout: ReturnType<typeof setTimeout> | null;

export const setSessionTokensRefreshTimeout = async (session: Session) => {
  // if (sessionTokenRefreshTimeout || window.PLATFORM !== 'web') return;
  if (sessionTokenRefreshTimeout) return;
  sessionTokenRefreshTimeout = setTimeout(async () => {
    if (new Date().getTime() / 1000 - 15 * 60 * 1000 < +((await getLastActiveTime()) ?? 0)) {
      log('refresh session by getLastActiveTime timer');
      refreshSession(await getSession());
    }
    sessionTokenRefreshTimeout = null;
  }, (session.claims.exp - session.claims.iat) * 1000 * 0.65);
};

export const setLastActiveTime = () =>
  NBXStorage.setItem(SESSION_LAST_ACTIVE_TIME_EPOCH, Math.floor(new Date().getTime() / 1000));

export const getLastActiveTime = () => NBXStorage.getItem(SESSION_LAST_ACTIVE_TIME_EPOCH);
