import humps from 'humps';
// eslint-disable-next-line import/no-cycle
import { context } from '../auth';
// eslint-disable-next-line import/no-cycle
import { get } from '../fetch-api';

export interface Asset {
  addressFormat: string;
  addressUri: string;
  decimalPrecision: number;
  decimalPrecisionDisplay: number;
  depositConfirmations: number;
  displayPrecision: number;
  feePrecision: number;
  fiat: boolean;
  id: string;
  maxOrderPrecision: number;
  maxWithdrawalPrecision: number;
  minWithdrawalQuantity: string;
  name: string;
  pegged: boolean;
  status: string;
  statusDescription?: string;
  transactionUri: string;
  type: string;
  withdrawalAmountMinimum: string;
  withdrawalConfirmations: number;
  withdrawalFee: string;
}

const getAddressFormat = (type: string, assetid: string): string => {
  if (type.includes('BTC')) return 'BTC';
  if (type.includes('ADA')) return 'ADA';
  if (type.includes('ETH-ERC20')) return 'ETH';
  return assetid;
};

export async function fetchAndFormatAssets(
  assetsFromJWT = {},
  accountToken?: string
): Promise<Record<string, Asset>> {
  const assetsInfo = await NBXAssets.getAssets(accountToken);
  const formattedAssets = {};
  assetsInfo.forEach(asset => {
    formattedAssets[asset.id] = {
      ...asset,
      // Translate to old data format for compat
      fiat: asset.type === 'FIAT',
      pegged: asset.id === 'USDC',
      addressFormat: getAddressFormat(asset.type, asset.id),
      minWithdrawalQuantity: asset.withdrawalAmountMinimum,
      feePrecision: asset.decimalPrecision,
      maxOrderPrecision: asset.decimalPrecision,
      maxWithdrawalPrecision: asset.decimalPrecision,
      displayPrecision: asset.decimalPrecisionDisplay,
      // Override withdrawal fee with value from JWT
      ...humps.camelizeKeys(assetsFromJWT[asset.id])
    };
  });
  return formattedAssets;
}

const NBXAssets = {
  // TODO: I don't love this
  getBaseUrl: (): void => {
    return window.API_CONFIG?.api.assets?.baseUrl;
  },

  getAssets: async (accountToken?: string): Promise<Array<Asset>> => {
    let accountContext;
    if (!accountToken) {
      try {
        accountContext = await context.account();
      } catch (error) {
        console.warn('failed to get account context, fetching public assets');
      }
    } else {
      accountContext = { token: accountToken };
    }
    return get(`${NBXAssets.getBaseUrl()}/?helpers=1/`, accountContext) as Promise<Array<Asset>>;
  }
};

export default NBXAssets;
